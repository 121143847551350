import React, {Component } from 'react';
import { PieChart, Pie, Sector, Cell, ResponsiveContainer,Legend } from 'recharts';

const data = [
  { name: 'UN', value: 400 ,fill:"#004d99"},
  { name: 'BCB', value: 300,fill:"#cc66ff" },
  { name: 'VA', value: 300, fill:"#ff8080"},
  { name: 'UMC', value: 200, fill:"#ffd24d"},
  { name: 'Medicare', value: 278, fill:"#70db70" },
  { name: 'Cigna', value: 189, fill:"#b8b894"},
];
 class DenialPayerChart extends Component {
//   static demoUrl = 'https://codesandbox.io/s/pie-chart-of-straight-angle-oz0th';

  render() {
    return (
      <div>
       
        <PieChart width={360} height={350} style={{top:30}}>
          <Pie
            dataKey="value"
            startAngle={180}
            endAngle={0}
            data={data}
            cx="50%"
            cy="50%"
            outerRadius={120}
            
            
          />
           <Legend style={{fontFamily:"popins"}} wrapperStyle={{top:250}}/>
        </PieChart>
      
      </div>
    );
  }
}
export default DenialPayerChart;

// import { Component } from "react";
// import jsPDF from "jspdf";
// import { Button } from "reactstrap";
// import Outcomes from "./Outcomes";
// class PdfDownload extends Component {

//   render() {
//     return (
//       <div>
//         <Button
//           onClick={() => {
//             alert("Downloaded SucessFully");
//             this.pdfGenerate();
//           }}
//         >
//           {" "}
//           Download
//         </Button>
//       </div>
//     );
//   }
// }
// export default PdfDownload;

import React, { Component } from "react";
import axios from "axios";
import {
  Modal,
  Row,
  Col,
} from "react-bootstrap";
import Marquee from "react-fast-marquee";
import { Input } from "reactstrap";
import {
  IoIosCall,
  IoIosKeypad,
  IoIosRecording,
} from "react-icons/io";
import { RiUserVoiceFill } from "react-icons/ri";
import { MdCallEnd } from "react-icons/md";
import {  Tooltip } from "antd";

let mobilenumberErr;
let internationalcodeerr;
export default class Twilio extends Component {
  constructor(props) {
    super(props);
    this.state = {
      callbutton: false,
      keypadmenu: false,
      recordbutton: false,
      internationalcode: "+1",
      keypad: "",
      dialnumber: [],
      mobilenumber: this.props.value11,
      voicetotext: false,
      callmodule: false,
      callended: false,
    };
  }
  validate = () => {
    let hasErr = "true";
    let internationalcodeerr = "";
    let mobilenumberErr = "";
    if (this.state.mobilenumber === "") {
      mobilenumberErr = "This field is required";
      this.setState({ mobilenumberErr });
      hasErr = false;
    }
    if (this.state.internationalcode === "") {
      internationalcodeerr = "This field is required";
      this.setState({ internationalcodeerr });
      hasErr = false;
    }
    return hasErr;
  };
  getDetails = async () => {
    const isValid = this.validate();

    if (isValid) {
      const value = {
        phone_no: this.state.internationalcode + this.state.mobilenumber,
      };

         axios
        .post(`http://localhost:3000/users/makeacall`, value)
        .then(() => {})
        .catch(() => {});
    }
  };
  handleClose = () => {
    this.setState({ callmodule: !this.state.callmodule });
  };
  render() {
    return (
      <div>
        <IoIosCall
          style={{
            width: "5vw",
            cursor: "pointer",
            padding: "8px",
            // backgroundColor: "#7BE382",
            color: "#041c3c",
            marginLeft: "1px",
            height: "6vh",
          }}
          onClick={this.handleClose}
        />

        {this.state.callmodule && (
          <Modal
            centered
            top
            size="sm"
            show={this.state.callmodule}
            onHide={() => {
              this.setState({ callmodule: !this.state.callmodule });
            }}
            backdrop="static"
            keyboard={false}
          >
            <Modal.Header style={{ height: "0vh" }} closeButton closeLabel="">
              {/* {this.state.callbutton && <label style={{color:"white",backgroundColor:"#041c3c", borderRadius:"10px"}}>Connecting...</label>} */}
            </Modal.Header>
            <div>
              <Modal.Body style={{ marginTop: "1vh", height: "10vh" }}>
                <Row>
                  <Col md="3">
                    <Input
                      type="select"
                      required
                      style={{
                        marginLeft: "-10px",
                        width: "75px",
                        height: "40px",
                        fontSize: "15px",
                        fontFamily: "serif",
                        fontWeight: "bold",
                        color: "#20a7db",
                      }}
                      value={this.state.internationalcode}
                      onChange={(e) => {
                        this.setState({ internationalcode: e.target.value });
                      }}
                    >
                      <option
                        value="+91"
                        style={{
                          fontSize: "15px",
                          fontFamily: "serif",
                          fontWeight: "bold",
                          color: "#20a7db",
                        }}
                      >
                        +91
                      </option>
                      &nbsp;
                      <option
                        value="+1"
                        style={{
                          fontSize: "15px",
                          fontFamily: "serif",
                          fontWeight: "bold",
                          color: "#20a7db",
                        }}
                      >
                        +1
                      </option>
                      <option
                        value="+44"
                        style={{
                          fontSize: "15px",
                          fontFamily: "serif",
                          fontWeight: "bold",
                          color: "#20a7db",
                        }}
                      >
                        +44
                      </option>
                      <option
                        value="+49"
                        style={{
                          fontSize: "15px",
                          fontFamily: "serif",
                          fontWeight: "bold",
                          color: "#20a7db",
                        }}
                      >
                        +49
                      </option>
                      <option
                        value="+65"
                        style={{
                          fontSize: "15px",
                          fontFamily: "serif",
                          fontWeight: "bold",
                          color: "#20a7db",
                        }}
                      >
                        +65
                      </option>
                    </Input>
                    {internationalcodeerr === "" && (
                      <label>Please Select the internationalcode</label>
                    )}
                  </Col>
                  <Col md="7">
                    <Input
                      style={{ width: "13vw" }}
                      type="text"
                      required
                      value={this.state.mobilenumber}
                      onChange={(e) => {
                        this.setState({ mobilenumber: e.target.value });
                      }}
                    ></Input>
                    {mobilenumberErr === "" && (
                      <label>*Please Enter the Mobile Number</label>
                    )}
                  </Col>

                  <Col md="2">
                    <IoIosCall
                      style={{
                        width: "3vw",
                        cursor: "pointer",
                        padding: "8px",
                        backgroundColor: "#4CBB17",
                        color: "white",
                        marginLeft: "1px",
                        height: "37px",
                      }}
                      onClick={() => {
                        this.setState({ callbutton: !this.state.callbutton });
                        this.getDetails();
                      }}
                    />
                  </Col>
                </Row>
              </Modal.Body>
              {this.state.mobilenumber !== "" &&
                this.state.internationalcode !== "" &&
                this.state.callbutton &&
                !this.state.callended && (
                  <Modal.Body>
                    <div style={{ marginLeft: "4vw" }}>
                      <Col>
                        <button
                          style={{
                            width: "3vw",
                            height: "7vh",
                            borderRadius: "50vw ",
                            backgroundColor: "White",
                            fontWeight: "Bold",
                            fontFamily: "serif",
                            fontSize: "4vh",
                          }}
                          onClick={() => this.sendDigit("1")}
                        >
                          1
                        </button>
                        &nbsp;&nbsp;&nbsp;
                        <button
                          style={{
                            width: "3vw",
                            height: "7vh",
                            borderRadius: "50vw ",
                            backgroundColor: "White",
                            fontWeight: "Bold",
                            fontFamily: "serif",
                            fontSize: "4vh",
                          }}
                          onClick={() => this.sendDigit("2")}
                        >
                          2
                        </button>
                        <span></span>
                        &nbsp;&nbsp;&nbsp;
                        <button
                          style={{
                            width: "3vw",
                            height: "7vh",
                            borderRadius: "50vw ",
                            backgroundColor: "White",
                            fontWeight: "Bold",
                            fontFamily: "serif",
                            fontSize: "4vh",
                          }}
                          onClick={() => this.sendDigit("3")}
                        >
                          3
                        </button>
                        &nbsp;&nbsp;&nbsp;
                      </Col>
                      <Col>
                        &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;
                        &nbsp;
                        <label style={{ fontSize: "2vh", fontFamily: "serif" }}>
                          A B C
                        </label>{" "}
                        &nbsp;&nbsp;&nbsp;
                        <label style={{ fontSize: "2vh", fontFamily: "serif" }}>
                          D E F
                        </label>{" "}
                        &nbsp;&nbsp;&nbsp;
                      </Col>
                      <Col>
                        <button
                          style={{
                            width: "3vw",
                            height: "7vh",
                            borderRadius: "50vw ",
                            backgroundColor: "White",
                            fontWeight: "Bold",
                            fontFamily: "serif",
                            fontSize: "4vh",
                          }}
                          onClick={() => this.sendDigit("4")}
                        >
                          4
                        </button>
                        &nbsp;&nbsp;&nbsp;
                        <button
                          style={{
                            width: "3vw",
                            height: "7vh",
                            borderRadius: "50vw ",
                            backgroundColor: "White",
                            fontWeight: "Bold",
                            fontFamily: "serif",
                            fontSize: "4vh",
                          }}
                          onClick={() => this.sendDigit("5")}
                        >
                          5
                        </button>
                        &nbsp;&nbsp;&nbsp;
                        <button
                          style={{
                            width: "3vw",
                            outlineColor: "!important",
                            height: "7vh",
                            borderRadius: "50vw ",
                            backgroundColor: "White",
                            fontWeight: "Bold",
                            fontFamily: "serif",
                            fontSize: "4vh",
                          }}
                          onClick={() => this.sendDigit("6")}
                        >
                          6
                        </button>
                        &nbsp;&nbsp;&nbsp;
                      </Col>
                      <Col>
                        <label style={{ fontSize: "2vh", fontFamily: "serif" }}>
                          {" "}
                          G H I
                        </label>{" "}
                        &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;
                        <label style={{ fontSize: "2vh", fontFamily: "serif" }}>
                          J K L
                        </label>{" "}
                        &nbsp;&nbsp;&nbsp;
                        <label style={{ fontSize: "2vh", fontFamily: "serif" }}>
                          {" "}
                          M N O
                        </label>{" "}
                        &nbsp;&nbsp;&nbsp;
                      </Col>
                      <Col>
                        <button
                          style={{
                            width: "3vw",
                            height: "7vh",
                            borderRadius: "50vw ",
                            backgroundColor: "White",
                            fontWeight: "Bold",
                            fontFamily: "serif",
                            fontSize: "4vh",
                          }}
                          onClick={() => this.sendDigit("7")}
                        >
                          7
                        </button>
                        &nbsp;&nbsp;&nbsp;
                        <button
                          style={{
                            width: "3vw",
                            height: "7vh",
                            borderRadius: "50vw ",
                            backgroundColor: "White",
                            fontWeight: "Bold",
                            fontFamily: "serif",
                            fontSize: "4vh",
                          }}
                          onClick={() => this.sendDigit("8")}
                        >
                          8
                        </button>
                        &nbsp;&nbsp;&nbsp;
                        <button
                          style={{
                            width: "3vw",
                            height: "7vh",
                            borderRadius: "50vw ",
                            backgroundColor: "White",
                            fontWeight: "Bold",
                            fontFamily: "serif",
                            fontSize: "4vh",
                          }}
                          onClick={() => this.sendDigit("9")}
                        >
                          9
                        </button>
                        &nbsp;&nbsp;&nbsp;
                      </Col>
                      <Col>
                        <label style={{ fontSize: "2vh", fontFamily: "serif" }}>
                          {" "}
                          P Q R S
                        </label>{" "}
                        &nbsp;&nbsp;&nbsp;
                        <label style={{ fontSize: "2vh", fontFamily: "serif" }}>
                          T U V
                        </label>{" "}
                        &nbsp;&nbsp;&nbsp;
                        <label style={{ fontSize: "2vh", fontFamily: "serif" }}>
                          W X Y Z
                        </label>{" "}
                        &nbsp;&nbsp;&nbsp;
                      </Col>
                      <Col>
                        <button
                          style={{
                            width: "3vw",
                            height: "7vh",
                            borderRadius: "50vw ",
                            backgroundColor: "White",
                            fontWeight: "Bold",
                            fontFamily: "serif",
                            fontSize: "4vh",
                          }}
                          onClick={() => this.sendDigit("*")}
                        >
                          *
                        </button>
                        &nbsp;&nbsp;&nbsp;
                        <button
                          style={{
                            width: "3vw",
                            height: "7vh",
                            borderRadius: "50vw ",
                            backgroundColor: "White",
                            fontWeight: "Bold",
                            fontFamily: "serif",
                            fontSize: "4vh",
                          }}
                          onClick={() => this.sendDigit("0")}
                        >
                          0
                        </button>
                        &nbsp;&nbsp;&nbsp;
                        <button
                          style={{
                            width: "3vw",
                            height: "7vh",
                            borderRadius: "50vw ",
                            backgroundColor: "White",
                            fontWeight: "Bold",
                            fontFamily: "serif",
                            fontSize: "4vh",
                          }}
                          onClick={() => this.sendDigit("#")}
                        >
                          #
                        </button>
                      </Col>
                      &nbsp;
                    </div>
                    {this.state.voicetotext && (
                      <Marquee>
                        Hey Boss this is your voice to text message
                      </Marquee>
                    )}
                    <Col>
                      <Tooltip title="Voice to Text">
                        <RiUserVoiceFill
                          style={{
                            width: "45px",
                            height: "45px",
                            color: "#242526",
                            backgroundColor: "#e4e6eb",
                            borderRadius: "10px",
                            marginLeft: "45px",
                            padding: "10px",
                          }}
                          onClick={() =>
                            this.setState({
                              voicetotext: !this.state.voicetotext,
                            })
                          }
                        />
                      </Tooltip>

                      <IoIosKeypad
                        style={{
                          width: "45px",
                          height: "45px",
                          color: "#242526",
                          backgroundColor: "#e4e6eb",
                          borderRadius: "10px",
                          marginLeft: "7px",
                          padding: "10px",
                        }}
                      />

                      {!this.state.recordbutton ? (
                        <Tooltip title="Do you want record">
                          <IoIosRecording
                            onClick={() => {
                              this.setState({
                                recordbutton: !this.state.recordbutton,
                              });
                            }}
                            style={{
                              width: "45px",
                              height: "45px",
                              color: "#242526",
                              backgroundColor: "#e4e6eb",
                              borderRadius: "10px",
                              marginLeft: "10px",
                              padding: "10px",
                            }}
                          />
                        </Tooltip>
                      ) : (
                        <Tooltip title="Recording">
                          <IoIosRecording
                            onClick={() => {
                              this.setState({
                                recordbutton: !this.state.recordbutton,
                              });
                            }}
                            style={{
                              width: "45px",
                              height: "45px",
                              color: "White",
                              backgroundColor: "#041c3c",
                              borderRadius: "10px",
                              marginLeft: "10px",
                              padding: "10px",
                            }}
                          />
                        </Tooltip>
                      )}
                    </Col>

                    <Col>
                      <MdCallEnd
                        style={{
                          width: "45px",
                          height: "45px",
                          color: "white",
                          backgroundColor: "red",
                          borderRadius: "50px",
                          cursor: "pointer",
                          marginLeft: "100px",
                          marginTop: "20px",
                          padding: "10px",
                        }}
                        onClick={() => {
                          this.setState({
                            callmodule: !this.state.callmodule,
                            recordbutton: false,
                            voicetotext: false,
                            internationalcodeerr: "",
                            mobilenumberErr,
                            callbutton: !this.state.callbutton,
                          });
                        }}
                      />
                    </Col>
                  </Modal.Body>
                )}
            </div>
          </Modal>
        )}
      </div>
    );
  }
}

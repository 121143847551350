import React,{Component} from 'react';
import{
    BarChart,
    Bar,
    XAxis,
    YAxis,
    Tooltip,
    ResponsiveContainer,
    Legend
} 
from 'recharts';

const getPath = (x, y, width, height) => `M${x},${y + height}
  C${x + width / 3},${y + height} ${x + width / 2},${y + height / 3} ${x + width / 2}, ${y}
  C${x + width / 2},${y + height / 3} ${x + (2 * width) / 3},${y + height} ${x + width}, ${y + height}Z`;
const TriangleBar = (props) => {
  const { fill, x, y, width, height } = props;
  return <path d={getPath(x, y, width, height)} stroke="none" fill={fill} />;};
const data=[
    {
        name:"Coding",
        totalamount:"5431"
    },
    {
        name:"Billing",
        totalamount:"1080"
    },
    {
        name:"frontend",
        totalamount:"1231"
    },
    {
        name:"Backend",
        totalamount:"5431"
    } 
];
class DenialCategoryChart extends Component{
    render()
    {
        return(
            <div>
                <ResponsiveContainer width={350} height={400} marginTop={10}>
          <BarChart
      width={380}
      height={450}
      data={data}
      margin={{
        right: 30,
        left: 20,
        bottom: 5
      }}
    >
      <XAxis dataKey="name" height={90} interval={0} angle={"-45"} minTickGap={6} tick={{strokeWidth:0,fontSize:"11",top:400,textAnchor:"end",dy:6}} />
      <YAxis dataKey="totalamount" hide />
      <Tooltip />
      <Legend />
      <Bar dataKey="totalamount" style={{}} shape={<TriangleBar />} 
          radius={[16,16 ,16, 16]} fill="#ff6666"/>
    </BarChart>
        </ResponsiveContainer>
            </div>
        )
    }
}
export default DenialCategoryChart;
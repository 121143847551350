import React,{Component} from 'react';
import { Card } from 'reactstrap';
import '../DenialDashboardCharts/topimpactor.css'
const data=[
    {
        title:"Category",
        data1:"Coding",
        data2:"Modifier Issue",
        data3:"One or more originally submitted procedure codes have been combined",
        data4:"75%",
        data5:"80%",
        data6:"89%"
    },
    {
        title:"Sub Category",
        data1:"Appeal",
        data2:"Additional Information",
        data3:"Missing or Invalid information",
        data4:"78%",
        data5:"65%",
        data6:"37%"
    },
    {
        title:"Reasons",
        data1:"Calling",
        data2:"Web Portal",
        data3:"Accepted for processing",
        data4:"65%",
        data5:"89%",
        data6:"90%"
    },
    {
        title:"ML Precision",
        data1:"Calling",
        data2:"Claim Process",
        data3:"NPT Acknowledges receipt of claim",
        data4:"90%",
        data5:"85%",
        data6:"40%",
        
    },
     
];
class TopImpactorTable extends Component{
    constructor(props)
    {
        super(props);
        this.setState={

        }
    }
    render()
    {
        return(
            <div>
                <Card>
                <table className='topimpactortable'>
                
                
                 <tr className='topimpactorrow'>
                  <th className='topimpactorheading'>Category</th>
                  <th className='topimpactorheading'>Sub Category</th>
                  <th className='topimpactorheading'>Reasons</th>
                  <th className='topimpactorheading'>ML Precision</th> 
                  <th className='topimpactorheading'>ML Recall</th>
                  <th className='topimpactorheading'>ML Accuracy</th>
                  </tr>
                 
                        {data.map((obj)=>{
                        return(
                            
                    <tr className='topimpactorrow'>
                        <td className='topimpactor'>{obj.data1}</td>
                        <td className='topimpactor'>{obj.data2}</td>
                        <td className='topimpactor'>{obj.data3}</td>
                        <td className='topimpactor'>{obj.data4}</td>
                        <td className='topimpactor'>{obj.data5}</td>
                        <td className='topimpactor'>{obj.data6}</td>
                    </tr>
                     )})}
                </table>
                </Card>
            </div>
        )
    }
}
export default TopImpactorTable;
import { React, Component } from "react";
import Newsidebar from "../../Dashboard/Newsidebar";
import { Table } from "reactstrap";
import { MdKeyboardArrowRight } from "react-icons/md";
import { Link } from "react-router-dom";
class DenialClaim extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ClaimID: sessionStorage.getItem("ClaimID"),
      ChartID: sessionStorage.getItem("ChartID"),
      MRN: sessionStorage.getItem("MRN"),
      Patient: sessionStorage.getItem("Patient"),
      Pripayer: sessionStorage.getItem("Pripayer"),
      DateOfService: sessionStorage.getItem("DateOfService"),
      ClaimDate: sessionStorage.getItem("ClaimDate"),
      PatientDOB: sessionStorage.getItem("PatientDOB"),
    };
  }
  render() {
    return (
      <div className="align-row" style={{ overflow: "hidden" }}>
        <Newsidebar name=" Claim Details" />
        <div className="align-column">
          <br></br>
          <div
            style={{ textAlign: "left", marginLeft: "1%", fontSize: "14px" }}
          >
            <p>
              <Link
                to="/denial-management"
                style={{ textDecoration: "none", color: "black" }}
              >
                Denial Management
              </Link>
              &nbsp;<MdKeyboardArrowRight></MdKeyboardArrowRight>&nbsp;
              <Link
                to="/denial/category"
                style={{ textDecoration: "none", color: "black" }}
              >
                Category
              </Link>
              &nbsp;<MdKeyboardArrowRight></MdKeyboardArrowRight>&nbsp;
              <Link
                to="/denial/sub-category"
                style={{ textDecoration: "none", color: "black" }}
              >
                Sub Category
              </Link>
              {/* &nbsp;<MdKeyboardArrowRight></MdKeyboardArrowRight>&nbsp;Details
              &nbsp;<MdKeyboardArrowRight></MdKeyboardArrowRight>&nbsp;ViewClaim */}
            </p>
          </div>
          <br></br>
          <div>
            {" "}
            <h5
              style={{ textAlign: "left", marginLeft: "1%", fontWeight: "600" }}
            >
              View Claim
            </h5>
          </div>
          <div>
            <Table
              className="table"
              style={{ marginLeft: "1%", width: "99%", marginTop: "2%" }}
            >
              <thead>
                <tr align="center">
                  <th>ClaimID</th>
                  <th>ChartID</th>
                  <th>MRN</th>
                  <th>PatientName</th>
                  <th>PatientDOB</th>
                  <th>Pripayer</th>
                  <th>Phone</th>
                  <th>Address</th>
                  <th>ClaimDate</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{this.state.ClaimID}</td>
                  <td>{this.state.ChartID}</td>
                  <td>{this.state.MRN}</td>
                  <td>{this.state.Patient}</td>
                  <td>{this.state.PatientDOB}</td>
                  <td>{this.state.Pripayer}</td>
                  <td>{this.state.ClaimID}</td>
                  <td>{this.state.ClaimID}</td>
                  <td>{this.state.ClaimID}</td>
                </tr>
              </tbody>
            </Table>
          </div>
          <br />
          <br></br>

          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
        </div>
      </div>
    );
  }
}
export default DenialClaim;

import React, { useEffect, useState } from "react";
import { Route, Redirect } from "react-router-dom";
import axios from "axios";
import {ip} from "../Apis/Api";
import "../.././Styles/LoadingSettings.scss";
const AccessRoute = ({ component: Component, role, ...rest }) => {
  const [rolenames, setRolenames] = useState([]);
  const [access, setAccess] = useState(false);
  const [loaded, setLoaded] = useState(false);

  const getrole = async () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${sessionStorage.getItem("accesstoken")}`, // Assuming accesstoken is stored in sessionStorage
    };
  
    try {
      // Fetch data from the API
      const res = await axios.get(`${ip}/role/all`, { headers });

  
      // Extract role names
      const fetchedRolenames = res.data.role.map((data) => data.role_name);
  
      // Store the role names in sessionStorage and update state
      sessionStorage.setItem("getRolenamesdata", JSON.stringify(fetchedRolenames));
      setRolenames(fetchedRolenames);
      setAccess(true);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    getrole().then(() => setLoaded(true));
  }, []);

  if (!loaded) {
    return <div className="loader-container">
    <div className="loader">
      <div className="loader_overlay"></div>
      <div className="loader_cogs">
        <div className="loader_cogs__top">
          <div className="top_part"></div>
          <div className="top_part"></div>
          <div className="top_part"></div>
          <div className="top_hole"></div>
        </div>
        <div className="loader_cogs__left">
          <div className="left_part"></div>
          <div className="left_part"></div>
          <div className="left_part"></div>
          <div className="left_hole"></div>
        </div>
        <div className="loader_cogs__bottom">
          <div className="bottom_part"></div>
          <div className="bottom_part"></div>
          <div className="bottom_part"></div>
          <div className="bottom_hole"></div>
        </div>
        <h3 className="loader_heading">Loading...</h3>
      </div>
    </div>
  </div>
  
  }

  const storedRole = sessionStorage.getItem("role_type");

  return (
    <Route
      {...rest}
      render={(props) =>
        rolenames.includes(storedRole)  && access ? (
          <Component {...props} />
        ) : (
          <Redirect to="/" />
        )
      }
    />
  );
};

export default AccessRoute;

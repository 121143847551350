import React,{Component} from 'react'
import './tabledata.css'

const data=[
    {
        cptcode:"99053",
        totaldenial:"61.80%",
        totalcount:"66.49%",
        denialchagre:"$67,717",
        denialcount:"691"

    },
    {
        cptcode:"99054",
        totaldenial:"41.80%",
        totalcount:"46.49%",
        denialchagre:"$47,717",
        denialcount:"491"

    },
    {
        cptcode:"99056",
        totaldenial:"81.80%",
        totalcount:"86.49%",
        denialchagre:"$87,717",
        denialcount:"891"

    },
    {
        cptcode:"99058",
        totaldenial:"71.80%",
        totalcount:"76.49%",
        denialchagre:"$77,717",
        denialcount:"791"

    },
    {
        cptcode:"99052",
        totaldenial:"21.80%",
        totalcount:"26.49%",
        denialchagre:"$27,717",
        denialcount:"291"

    },
    {
        cptcode:"99045",
        totaldenial:"11.80%",
        totalcount:"16.49%",
        denialchagre:"$17,717",
        denialcount:"191"

    },
    {
        cptcode:"99054",
        totaldenial:"81.80%",
        totalcount:"86.49%",
        denialchagre:"$87,717",
        denialcount:"891"

    },
    {
        cptcode:"99067",
        totaldenial:"51.80%",
        totalcount:"56.49%",
        denialchagre:"$5,717",
        denialcount:"591"

    },
    {
        cptcode:"98059",
        totaldenial:"31.80%",
        totalcount:"26.49%",
        denialchagre:"$37,717",
        denialcount:"391"

    },
]
class TableData extends Component
{
    render(){
        return(
            <div >
                <table className='denialtablename'>
                    <tr className='denialtablerow'>
                        <th className='denialtableheading'>Top Impactors</th>
                        <th className='denialtableheading'>% of Total Denial</th>
                        <th className='denialtableheading'>% of Total Count</th>
                        <th className='denialtableheading'>Denied Charges</th>
                        <th className='denialtableheading'>Count</th>
                    </tr>
                    {
                        data.map((obj)=>{
                        return(
                    <tr className='denialtablerow'>
                        <td className='denialtabledata'>{obj.cptcode}</td>
                        <td className='denialtabledata'>{obj.totaldenial}</td>
                        <td className='denialtabledata'>{obj.totalcount}</td>
                        <td className='denialtabledata'>{obj.denialchagre}</td>
                        <td className='denialtabledata'>{obj.denialcount}</td>
                    </tr>)})}
                </table>
            </div>
        )
    }
}
export default TableData;
import React,{Component} from 'react';
import{
    XAxis, YAxis,Tooltip, Line, LabelList,Bar,ResponsiveContainer,LineChart, ComposedChart
} from 'recharts';
const data=[
     {
         age:"2019-10",
         denialcharge:"443057",
         denialrate:"3.51"
     },
     {
        age:"2019-11",
        denialcharge:"373699",
        denialrate:"3.17"
    },
    {
        age:"2019-12",
        denialcharge:"443057",
        denialrate:"3.11"
    },
    {
        age:"2020-1",
        denialcharge:"362468",
        denialrate:"2.44"
    },
    {
        age:"2020-2",
        denialcharge:"354039",
        denialrate:"2.41"
    },
    {
        age:"2020-3",
        denialcharge:"121324",
        denialrate:"2.07"
    }

];
class DenialPredictionChart extends Component{
    render()
    {
        return(
            <div>
                 {this.props.data === "$" && 
                 
          <ComposedChart
         
            width={340}
            height={400}
            data={data}
            stopColor="red"
            margin={{
              top: 10,
              right: 30,
              left: 10,
              bottom: 5,
            }}
          >
            <XAxis dataKey="age" height={90} interval={0} angle={"-45"} minTickGap={6} tick={{strokeWidth:0,fontSize:"11",top:400,textAnchor:"end",dy:6}}/>
            <YAxis dataKey="denialcharge"  />
            <Tooltip />
            <defs>
      <linearGradient
        id="colorYv"
        x1="0"
        y1="0"
        x2="0"
        y2="100%"
        spreadMethod="reflect"
      >
        <stop offset="0" stopColor= "#0033cc"/>
        <stop offset="1" stopColor="#33ccff" />
      </linearGradient>
    </defs>
            <Bar
              name="denialcharge"
              dataKey="denialcharge"
              barSize={30}
              fill="url(#colorYv)"
              textAnchor='end'
            >
              {/* <LabelList dataKey="denialcharge" type="number" position='top' fill='#7a051b' fontWeight='600' fontFamily='Poppins' /> */}
            </Bar>
            {/* <Line name="Total Amount" type="monotoneX" dataKey="totalclaims" stroke="#8884d8" /> */}
           
          </ComposedChart>
         
    }
                 {this.props.data==="#" &&
                 
          <LineChart
            width={340}
            height={400}
            data={data}
            margin={{
              top: 10,
              right: 30,
              left: 20,
              bottom: 5,
            }}
          >
            <XAxis dataKey="age" height={90} interval={0} angle={"-45"} minTickGap={6} tick={{strokeWidth:0,fontSize:"11",top:400,textAnchor:"end",dy:6}}/>
            <YAxis dataKey="denialrate" />
            <Tooltip />
            <Line
              type="monotone"
              dataKey="denialrate"
              stroke="rgb(247, 73, 105)"
              activeDot={{ r: 8 }}
            />
            {/* <Line type="monotone" dataKey="Totalcount" stroke="rgb(5, 101, 191)" /> */}
          </LineChart>
        
  }
            </div>
        )
    }
}
export default DenialPredictionChart;
import { Component } from "react";
import "antd/dist/antd.css";
import axios from "axios";
import jsPDF from "jspdf";
import { PDFExport } from "@progress/kendo-react-pdf";
import {  Input } from "reactstrap";
import moment from "moment";
import Newsidebar from "../Dashboard/Newsidebar";
import {  DatePicker} from "antd";
import "react-best-tabs/dist/index.css";
import "../../Styles/charts.css";
import { BiCloudDownload } from "react-icons/bi";
import "react-bootstrap-typeahead/css/Typeahead.css";
import "react-bootstrap-typeahead/css/Typeahead.bs5.css";
import { Typeahead } from "react-bootstrap-typeahead";
import {
  ButtonDropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";
import Ageingchart from "./ARcharts/Ageingchart";
import PripayerChart from "./ARcharts/PripayerChart";
import RUVCharts2 from "./ARcharts/RUV";
import ARbyage from "./ARcharts/ARbyage";
import InventoryCard from "./ARcharts/InventoryCard";
import ARbyCPT from "./ARcharts/ARbyCPT";
import Top5Payers from "./ARcharts/TopPayers";
import ARdepartChart2 from "./ARcharts/Ardepart2";
import "../../Styles/inventoryAnalysis.css";
import { ip } from "../Apis/Api";
import React from "react";
import AverageTouches from "./ARcharts/AverageTouches";
import {  notification } from "antd";

const openDownload = (success) => {
  notification[success]({
    message: "File is being downloaded",
  });
};
const { RangePicker } = DatePicker;
class ARManagement extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name_1: "$",
      day: "1",
      name_2: "$",
      name_3: "$",
      _id: "$",
      cptcode: "$",
      month: "$",
      yesterday: "",
      agegrpwise: [],
      pripayamount: [],
      cptwise: [],
      payercategorywise: [],
      claimType: [],
      type: "",
      chart: "arbycptwise",
      chartfortwo: "average",
      datekey: "",
      check: false,
      startdate: null,
      enddate: null,
      refreshtoken: sessionStorage.getItem("refreshtoken"),
      accesstoken: sessionStorage.getItem("accesstoken"),
      role_type: sessionStorage.getItem("role_type"),
      UserAllocate:
      sessionStorage.getItem("firstname") +
      " " +
      sessionStorage.getItem("lastname"),
      webcount: "",
      webamount: "",
      ivrcount: "",
      ivramount: "",
      manualamount: "",
      manualcount: "",
      valueOfInput1: moment().format("MM-DD-YYYY"),
      valueOfInput2: "",
      filterstatus: "custom",
      clientslist: [],
      client: sessionStorage.getItem("clientid"),
      clientname: "",
      opendropdown: false,
      locationslist: [],
      Location: "",
    };

    this.pdfRef = React.createRef();
  }

  //to renew accesstoken
  renewaccesstoken = async () => {
    const renewheaders = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.refreshtoken}`,
    };
    // eslint-disable-next-line
     await axios
      .get(`${ip}/auth/renewAccessToken`, {
        headers: renewheaders,
      })
      .then((renew) => {
        sessionStorage.setItem("accesstoken", renew.data.accessToken);
      })
      .catch((err) => {});
  };

  toggle = () => {
    this.setState({ opendropdown: !this.state.opendropdown });
  };
  // datefilter = (e) => {
  //   if ((this.state.filterstatus = "Yesterday")) {
  //     this.setState({
  //       valueOfInput1: e.target.value,

  //       valueOfInput2: moment().subtract(7, "days").format("MM-DD-YYYY"),
  //     });
  //   } else if ((this.state.filterstatus = "")) {
  //     this.setState({
  //       valueOfInput1: e.target.value,

  //       valueOfInput2: moment().format("MM-DD-YYYY"),
  //     });
  //   }
  // };
  handleChange = (selectedOptions) => {
    let Location = selectedOptions.map((data) => {
      return data.Location;
    });
    this.setState(
      {
        Location: Location[0],
      },
      () => {
        this.getclients();
        this.getLocation();
      }
    );
  };

  getclients = async () => {
    //api call to get all users
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    // eslint-disable-next-line
     axios
      .get(`${ip}/clientonboard/all`, { headers: headers })
      .then((res) => {
        if (res.data.clientonboard.length !== 0) {
          this.setState({
            clientslist: res.data.clientonboard,
            // clientname: res.data.clientonboard[0].client,
          });
        } else {
          this.setState({
            clientslist: res.data.clientonboard,
          });
        }
      })
      .catch((err) => {});
  };
  pdfGenerate = () => {
    const doc = new jsPDF();
    var html = document.getElementById("Value1").innerHTML;

    doc.text(html, 10, 10);
    doc.save("a4.pdf");
  };

  toggleCheck = () => {
    this.setState({ check: !this.state.check });
  };
  getLocation = () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    let url;
    if (this.state.client) {
      url = `${ip}/location/activelocations?clientId=${this.state.client}`;
    } else {
      url = `${ip}/location/activelocations`;
    }
     axios
      .get(url, { headers: headers })
      .then((res) => {
        let data = res.data.location;
        
        data = data.filter((item) => {
          return String(item.clientId) === String(this.state.client); // Adjust based on need
        });
      
        if (data.length > 0) {
          data.sort((a, b) => {
            if (!a.Location || !b.Location) {
              console.warn("Undefined or null Location in data during sort:", a, b);
              return 0;
            }
            return a.Location < b.Location ? -1 : 1;
          });
        } else {
          console.log("No data to sort after filtering.");
        }
        
        this.setState({ locationslist: data });  
      })

      .catch((err) => {});
  };
  valuedate = (option, e) => {
    if (option === "yesterday") {
      this.setState({
        valueOfInput1: e.target.value,
        valueOfInput2: moment().subtract(1, "days").format("MM-DD-YYYY"),
        filterstatus: option,
      });
    } else {
      this.setState({
        valueOfInput1: e.target.value,
        valueOfInput2: moment().format("MM-DD-YYYY"),
        filterstatus: option,
      });
    }
  };
  componentDidMount() {
    
    this.getclients();
    this.getLocation();
  }

  render() {
    return (
      <div>
      <Newsidebar name="Inventory Analysis" />
      <div className="align-row" style={{ overflow: "hidden" }}>
        <div className="align-column">
          <div
            forcePageBreak=".page-break"
            ref={this.pdfRef}
            className="align-row items"
          ></div>
          <br />
          <div>
            <div style={{ display: "flex", flexDirection: "row" , justifyContent:"space-between"}}>
            <div style={{marginLeft:"3%"}}>
              {this.state.role_type === "SuperAdmin" && (
                <ButtonDropdown
                  isOpen={this.state.opendropdown}
                  toggle={this.toggle}
                  style={{
                    width: "12%",
                    marginLeft: "4%",
                    position: "inherit",
                  }}
                >
                  <DropdownToggle caret>
                    {this.state.clientname ? this.state.clientname : "All"}
                  </DropdownToggle>
                  <DropdownMenu>
                    <DropdownItem
                      onClick={() =>
                        this.setState({ clientname: "", client: "" }, () => {
                          this.getLocation();
                        })
                      }
                    >
                      All
                    </DropdownItem>
                    {this.state.clientslist.map((e) => {
                      return (
                        <DropdownItem
                          id={e.clientId}
                          key={e.clientId}
                          onClick={(e) =>
                            this.setState(
                              {
                                client: e.target.id,
                                clientname: e.target.innerText,
                              },
                              () => {
                                this.getLocation();
                              }
                            )
                          }
                        >
                          {e.client}
                        </DropdownItem>
                      );
                    })}
                  </DropdownMenu>
                </ButtonDropdown>
              )}
              {this.state.client && (
                <Typeahead
                  id="inventory-typeahead"
                  clearButton={true}
                  onChange={this.handleChange}
                  options={this.state.locationslist}
                  labelKey={(option) => `${option.Location}`}
                  placeholder="All Location"
                  selected={this.state.selected}
                />
              )}
              </div>
              <div style={{display:"flex", flexDirection:"row"}}>
              <Input
              type="select"
              style={{ width: "fit-content",  fontSize:"13px"}}
              className="form-control form-select"
              onChange={(e) => {
                const index = e.target.selectedIndex;
                const el = e.target.childNodes[index];
                const option = el.getAttribute("title");
                this.valuedate(option, e);
              }}
            >
              <option value="" title="custom">
                Custom
              </option>
              <option value={moment().format("MM-DD-YYYY")} title="">
                Today
              </option>
              <option
                value={moment().subtract(1, "days").format("MM-DD-YYYY")}
                title="yesterday"
              >
                Yesterday
              </option>
              <option
                value={moment().subtract(3, "days").format("MM-DD-YYYY")}
                title=""
              >
                Last 3 days
              </option>
              <option
                value={moment().subtract(7, "days").format("MM-DD-YYYY")}
                title=""
              >
                Last 7 days
              </option>
              <option
                value={moment().subtract(30, "days").format("MM-DD-YYYY")}
                title=""
              >
                Last 30 days
              </option>
              <option
                value={moment().subtract(365, "days").format("MM-DD-YYYY")}
                title=""
              >
                Last 365 days
              </option>
            </Input> &nbsp;&nbsp;&nbsp; 
            {this.state.filterstatus === "custom" && (
              <RangePicker
                format="MM-DD-YYYY"
                onChange={(dates, datestring) => {
                  this.setState({
                    valueOfInput1: datestring[0],
                    valueOfInput2: datestring[1],
                  });
                }}
                height="50%"
                width="200%"
                style={{ borderColor: "gray"}}
              />
            )} &nbsp;&nbsp;&nbsp;    
            <button
            className="export-btn"
            style={{  marginRight: "2%" }}
            onClick={() => {
              openDownload("success");
              if (this.pdfRef.current) {
                this.pdfRef.current.save();
              }
            }}
          >
            <BiCloudDownload style={{ width: "30px", height: "30px" }} />
          </button>
              </div>
            </div>
          </div>
           <br/>
          <PDFExport
            forcePageBreak=".page-break"
            ref={this.pdfRef}
            fileName="Inventory.pdf"
          >
            <div className="inventory__align">
              <div>
                <InventoryCard
                  ageStart={this.state.valueOfInput1}
                  ageEnd={this.state.valueOfInput2}
                  client={this.state.client}
                  Location={this.state.Location}
                />
              </div>

              <div className="row">
              <div className="col-md-6">
                <div className="card">
                <div className="card-header bg-white border-0">
                <div style={{display:"flex", flexDirection:"row", justifyContent:"space-between"}}>
                <Input
                  type="select"
                  className="form-control form-select"
                  onChange={(e) =>
                    this.setState({ chart: e.target.value })
                  }
                  value={this.state.chart}
                >
                  <option value="arbycptwise">AR by CPT-Wise</option>
                  <option value="monthlycharges">Monthly Charges</option>
                  <option value="top5payers">Top 5 Payers</option>
                  <option value="agewise">Age-Wise</option>
                  <option value="arbypayerclass">
                    AR by Payer Class
                  </option>

                  <option value="pripayer">Pri Payer Wise</option>
                </Input> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                {this.state.chart === "top5payers" ?
                null :
                <Input
                type="select"
                className="form-control form-select"
                onChange={(e) =>
                  this.setState({ cptcode: e.target.value })
                }
                value={this.state.cptcode}
              >
                <option value="$">Charges</option>
                <option value="#">Claims</option>
              </Input>
                }
           
              </div>
                </div>
                <div className="card-body">
                {this.state.chart === "arbycptwise" && (
                  <div>
                    <ARbyCPT
                      startdate={this.state.valueOfInput1}
                      enddate={this.state.valueOfInput2}
                      cptwise={this.state.cptcode}
                      client={this.state.client}
                      Location={this.state.Location}
                    ></ARbyCPT>
                  </div>
                )}
                {this.state.chart === "monthlycharges" && (
                  <div>
                    <RUVCharts2
                      monthlyStart={this.state.valueOfInput1}
                      monthlyEnd={this.state.valueOfInput2}
                      charges={this.state.month}
                      client={this.state.client}
                      Location={this.state.Location}
                    ></RUVCharts2>
                  </div>
                )}
                {this.state.chart === "arbypayerclass" && (
                  <div>
                    <ARdepartChart2
                      classStart={this.state.valueOfInput1}
                      classEnd={this.state.valueOfInput2}
                      payercategorywise={this.state.name_1}
                      client={this.state.client}
                      Location={this.state.Location}
                    ></ARdepartChart2>
                  </div>
                )}
                {this.state.chart === "agewise" && (
                  <div>
                    <Ageingchart
                      ageStart={this.state.valueOfInput1}
                      ageEnd={this.state.valueOfInput2}
                      agegrpwise={this.state._id}
                      client={this.state.client}
                      Location={this.state.Location}
                    ></Ageingchart>
                  </div>
                )}
                {this.state.chart === "pripayer" && (
                  <div>
                    <PripayerChart
                      pripayerStart={this.state.valueOfInput1}
                      pripayerEnd={this.state.valueOfInput2}
                      pripayamount={this.state.name_3}
                      client={this.state.client}
                      Location={this.state.Location}
                    ></PripayerChart>
                  </div>
                )}
                {this.state.chart === "top5payers" && (
                  <div>
                    <Top5Payers
                      topStart={this.state.valueOfInput1}
                      topEnd={this.state.valueOfInput2}
                      client={this.state.client}
                      Location={this.state.Location}
                    ></Top5Payers>
                  </div>
                )}
                </div>    
                </div>
                </div>
                <div className="col-md-6">
                <div className="card">
                <div className="card-header bg-white border-0">
                <div>
                <Input
                  type="select"
                  className="form-control form-select"
                  onChange={(e) =>
                    this.setState({ chartfortwo: e.target.value })
                  }
                  value={this.state.chartfortwo}
                >
                <option value="average">
                Average Number of Touches
              </option>
                  <option value="daywise">Day-Wise Fresh Claims</option>
                </Input>

              </div>
                </div>
                <div className="card-body">
                {this.state.chartfortwo === "daywise" && (
                    <ARbyage
                      dayStart={this.state.valueOfInput1}
                      dayEnd={this.state.valueOfInput2}
                      client={this.state.client}
                      Location={this.state.Location}
                    ></ARbyage>
                )}
                {this.state.chartfortwo === "average" && (
                  <div>
                    <AverageTouches
                      touchStart={this.state.valueOfInput1}
                      touchEnd={this.state.valueOfInput2}
                      client={this.state.client}
                      Location={this.state.Location}
                    ></AverageTouches>
                  </div>
                )}
                </div>

              </div>
                </div>
              </div>
            </div>
          </PDFExport>
        </div>
      </div>
      </div>
    );
  }
}
export default ARManagement;

import { React, Component } from "react";
import Newsidebar from "../Dashboard/Newsidebar";
import workgif from "./../../Images/workingdoc.gif";
class PrimaryPayer extends Component {
  render() {
    return (
      <div>
      <Newsidebar />
      <div className="align-row" style={{overflow:"hidden"}}>
        <div style={{ width: "100%" }}>
          <img src={workgif} alt="Animation" width="700px" height="700px" />
          <h3
            style={{
              color: "#00008B",
              display: "flex",
              justifyContent: "center",
              marginTop: "-11%",
            }}
          >
            Great things are coming soon
          </h3>
        </div>
      </div>
      </div>
    );
  }
}
export default PrimaryPayer;

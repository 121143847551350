import { Component } from "react";
import "antd/dist/antd.css";
import axios from "axios";
import { Row, Col } from "reactstrap";
import moment from "moment";
import { DatePicker } from "antd";
import "react-best-tabs/dist/index.css";
import "../../../Styles/inventoryAnalysis.css";
import { Link } from "react-router-dom";
import { CgNotes } from "react-icons/cg";
import { BsClipboardData } from "react-icons/bs";
import { SiDatabricks } from "react-icons/si";
import { ip } from "../../Apis/Api";
import React from "react";
import Claimallocation from "../../Claims/Claimallocation";

// const pdfExportComponent = React.useRef(null);
const ref = React.createRef();

const { RangePicker } = DatePicker;
let url;
class InventoryCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name_1: "$",
      day: "1",
      name_2: "$",
      name_3: "$",
      _id: "$",
      cptcode: "$",
      month: "$",
      agegrpwise: [],
      pripayamount: [],
      cptwise: [],
      payercategorywise: [],
      claimType: [],
      type: "",
      check: false,
      ageStart: null,
      ageEnd: null,
      refreshtoken: sessionStorage.getItem("refreshtoken"),
      accesstoken: sessionStorage.getItem("accesstoken"),
      role: sessionStorage.getItem("role_type"),
      webcount: "",
      webamount: "",
      ivrcount: "",
      ivramount: "",
      manualamount: "",
      manualcount: "",
      valueOfInput1: moment().format("MM-DD-YYYY"),
      valueOfInput2: "",
      selecteddate: "custom",
      clientslist: [],
      client: sessionStorage.getItem("clientid"),
      clientname: "",
      opendropdown: false,
      v1: "",
      v2: "",
    };

    this.pdfRef = React.createRef();
  }

  //to renew accesstoken
  renewaccesstoken = async () => {
    const renewheaders = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.refreshtoken}`,
    };
    // eslint-disable-next-line
    await axios
      .get(`${ip}/auth/renewAccessToken`, {
        headers: renewheaders,
      })
      .then((renew) => {
        sessionStorage.setItem("accesstoken", renew.data.accessToken);
      })
      .catch((err) => {});
  };

  toggle = () => {
    this.setState({ opendropdown: !this.state.opendropdown });
  };

  getinventoryweb = () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    let url = null;
    if (this.props.client) {
      if (this.props.Location) {
        if (this.props.ageStart !== "" && this.props.ageEnd !== "") {
          url = `${ip}/inventory/web?start_date=${this.props.ageStart}&end_date=${this.props.ageEnd}&clientId=${this.props.client}&Location=${this.props.Location}`;
        } else {
          url = `${ip}/inventory/web?clientId=${this.props.client}&Location=${this.props.Location}`;
        }
      } else {
        if (this.props.ageStart !== "" && this.props.ageEnd !== "") {
          url = `${ip}/inventory/web?start_date=${this.props.ageStart}&end_date=${this.props.ageEnd}&clientId=${this.props.client}`;
        } else {
          url = `${ip}/inventory/web?clientId=${this.props.client}`;
        }
      }
    } else {
      if (this.props.ageStart !== "" && this.props.ageEnd !== "") {
        url = `${ip}/inventory/web?start_date=${this.props.ageStart}&end_date=${this.props.ageEnd}`;
      } else {
        url = `${ip}/inventory/web`;
      }
    }
    const req = axios
      .get(url, { headers: headers })
      .then((req) => {
        if (req.data.web.length !== 0) {
          this.setState({ webcount: req.data.web[0].totalcount });
          this.setState({
            webamount: req.data.web[0].totalamount,
          });
        } else {
          this.setState({ webcount: 0 });
          this.setState({ webamount: 0 });
        }
      })
      .catch((err) => {});
  };
  getinventoryivr = () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    let url = null;
    if (this.props.client) {
      if (this.props.Location) {
        if (this.props.ageStart !== "" && this.props.ageEnd !== "") {
          url = `${ip}/inventory/ivr?start_date=${this.props.ageStart}&end_date=${this.props.ageEnd}&clientId=${this.props.client}&Location=${this.props.Location}`;
        } else {
          url = `${ip}/inventory/ivr?clientId=${this.props.client}&Location=${this.props.Location}`;
        }
      } else {
        if (this.props.ageStart !== "" && this.props.ageEnd !== "") {
          url = `${ip}/inventory/ivr?start_date=${this.props.ageStart}&end_date=${this.props.ageEnd}&clientId=${this.props.client}`;
        } else {
          url = `${ip}/inventory/ivr?clientId=${this.props.client}`;
        }
      }
    } else {
      if (this.props.ageStart !== "" && this.props.ageEnd !== "") {
        url = `${ip}/inventory/ivr?start_date=${this.props.ageStart}&end_date=${this.props.ageEnd}`;
      } else {
        url = `${ip}/inventory/ivr`;
      }
    }
    const req = axios
      .get(url, { headers: headers })
      .then((req) => {
        if (req.data.ivr.length !== 0) {
          this.setState({ ivrcount: req.data.ivr[0].totalcount });
          this.setState({
            ivramount: req.data.ivr[0].totalamount,
          });
        } else {
          this.setState({ ivrcount: 0 });
          this.setState({ ivramount: 0 });
        }
      })
      .catch((err) => {});
  };
  getinventorymanual = () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    let url = null;
    if (this.props.client) {
      if (this.props.Location) {
        if (this.props.ageStart !== "" && this.props.ageEnd !== "") {
          url = `${ip}/inventory/manual?start_date=${this.props.ageStart}&end_date=${this.props.ageEnd}&clientId=${this.props.client}&Location=${this.props.Location}`;
        } else {
          url = `${ip}/inventory/manual?clientId=${this.props.client}&Location=${this.props.Location}`;
        }
      } else {
        if (this.props.ageStart !== "" && this.props.ageEnd !== "") {
          url = `${ip}/inventory/manual?start_date=${this.props.ageStart}&end_date=${this.props.ageEnd}&clientId=${this.props.client}`;
        } else {
          url = `${ip}/inventory/manual?clientId=${this.props.client}`;
        }
      }
    } else {
      if (this.props.ageStart !== "" && this.props.ageEnd !== "") {
        url = `${ip}/inventory/manual?start_date=${this.props.ageStart}&end_date=${this.props.ageEnd}`;
      } else {
        url = `${ip}/inventory/manual`;
      }
    }
    const req = axios
      .get(url, { headers: headers })
      .then((req) => {
        if (req.data.manual.length !== 0) {
          this.setState({
            manualcount: req.data.manual[0].totalcount,
          });
          this.setState({
            manualamount: req.data.manual[0].totalamount,
          });
        } else {
          this.setState({ manualcount: 0 });
          this.setState({ manualamount: 0 });
        }
      })
      .catch((err) => {});
  };
  componentDidMount() {
    
    this.getinventoryweb();
    this.getinventoryivr();
    this.getinventorymanual();
  }

  componentDidUpdate(prevProps) {
    if (
      !(
        this.props.ageStart === prevProps.ageStart &&
        this.props.ageEnd === prevProps.ageEnd
      )
    ) {
      this.getinventoryweb();
      this.getinventoryivr();
      this.getinventorymanual();
    } else if (this.props.client !== prevProps.client) {
      this.getinventoryweb();
      this.getinventoryivr();
      this.getinventorymanual();
    } else if (this.props.Location !== prevProps.Location) {
      this.getinventoryweb();
      this.getinventoryivr();
      this.getinventorymanual();
    }
    //  debugger;
  }

  render() {
    return (
      <div>
        <div className="row">
          <div className="col-md-4 mb-4">
            <div className="card">
              <div className="card-header bg-white border-0 p-4">
                <Row style={{ marginLeft: "1%" }}>
                  <Col className="col-1">
                    <div className="iconsCircle" style={{ width: "42px" }}>
                      <SiDatabricks
                        style={{ color: "green", fontSize: "14px" }}
                      ></SiDatabricks>
                    </div>
                  </Col>
                  <Col>
                    <Row style={{ color: "black", marginLeft: "45px" }}>
                      WEB
                    </Row>
                    <Row
                      style={{
                        color: "grey",
                        marginLeft: "45px",
                      }}
                    >
                      Claims through Web
                    </Row>
                  </Col>
                  <Col>
                    {this.state.role === "Admin" && (
                      <Claimallocation type="web" client={this.props.client} />
                    )}
                  </Col>
                </Row>
              </div>
              <div className="card-body">
                <Link       
                to={{
                  pathname: "/claims/Web",
                  startdate: this.props.ageStart,
                  enddate: this.props.ageEnd,
                  Location: this.props.Location,
                }}
                onClick={() =>
                  sessionStorage.setItem("queryclient", this.state.client)
                }
                style={{
                  textDecoration: "none",
                  color: "rgb(84 205 26)",
                }}
              >
                <Row
                onClick={() =>
                  sessionStorage.setItem("queryclient", this.state.client)
                }
                >
                  <Col    
                   className="alclaim"
                  >
                    Total Claims:
                    <span>
                      <div
                        style={{
                          color: "black",
                          fontWeight: "600",
                          fontSize: "13px",
                        }}
                      >
                        {this.state.webcount}
                      </div>
                    </span>
                  </Col>
                  <Col className="altotal">
                  Total Amount
                    <span>
                      {" "}
                      <div
                        style={{
                          color: "black",
                          fontSize: "13px",
                          fontWeight: "600",
                        }}
                      >
                      ${this.state.webamount.toLocaleString()}
                      </div>
                    </span>
                  </Col>
                </Row>
              </Link>
              </div>
            </div>
          </div>
          <div className="col-md-4 mb-4">
            <div className="card">
              <div className="card-header bg-white border-0 p-4">
                <Row style={{ marginLeft: "1%" }}>
                  <Col className="col-1">
                    <div
                      className="iconsCircle"
                      style={{
                        width: "42px",
                        background: "rgb(171,214,255)",
                      }}
                    >
                      <BsClipboardData
                        style={{ color: "blue", fontSize: "20px" }}
                      ></BsClipboardData>
                    </div>
                  </Col>
                  <Col>
                    <Row style={{ color: "black", marginLeft: "45px" }}>
                      IVR
                    </Row>
                    <Row
                      style={{
                        color: "grey",
                        marginLeft: "45px",
                      }}
                    >
                      Claims through IVR
                    </Row>
                  </Col>
                  <Col>
                    {" "}
                    {this.state.role === "Admin" && (
                      <Claimallocation type="ivr" client={this.props.client} />
                    )}
                  </Col>
                </Row>
              </div>
              <div className="card-body">
                <Link       
                to={{
                  pathname: "/claims/IVR",
                  startdate: this.props.ageStart,
                  enddate: this.props.ageEnd,
                  Location: this.props.Location,
                }}
                onClick={() =>
                  sessionStorage.setItem("queryclient", this.state.client)
                }
                style={{
                  textDecoration: "none",
                  color: "rgb(0 116 235)",
                }}
              >
                <Row
                onClick={() =>
                  sessionStorage.setItem("queryclient", this.state.client)
                }
                >
                  <Col    
                   className="alclaim"
                  >
                    Total Claims:
                    <span>
                      <div
                        style={{
                          color: "black",
                          fontWeight: "600",
                          fontSize: "13px",
                        }}
                      >
                        {this.state.ivrcount}
                      </div>
                    </span>
                  </Col>
                  <Col className="altotal">
                  Total Amount
                    <span>
                      {" "}
                      <div
                        style={{
                          color: "black",
                          fontSize: "13px",
                          fontWeight: "600",
                        }}
                      >
                      ${this.state.ivramount.toLocaleString()}
                      </div>
                    </span>
                  </Col>
                </Row>
              </Link>
              </div>
            </div>
          </div>
          <div className="col-md-4 mb-4">
            <div className="card">
              <div className="card-header bg-white border-0 p-4">
                <Row style={{ marginLeft: "1%" }}>
                  <Col className="col-1">
                    <div
                      className="iconsCircle"
                      style={{
                        width: "42px",
                        background: "rgb(228,196,240)",
                      }}
                    >
                      <CgNotes
                        style={{ color: "purple", fontSize: "20px" }}
                      ></CgNotes>
                    </div>
                  </Col>
                  <Col>
                    <Row style={{ color: "black", marginLeft: "45px" }}>
                      Manual
                    </Row>
                    <Row
                      style={{
                        color: "grey",
                        marginLeft: "45px",
                        whiteSpace:"nowrap"
                      }}
                    >
                      Claims through Manual
                    </Row>
                  </Col>
                  <Col>
                    {this.state.role === "Admin" && (
                      <Claimallocation
                        type="manual"
                        client={this.props.client}
                      />
                    )}
                  </Col>
                </Row>
              </div>
              <div className="card-body">
                <Link       
                to={{
                  pathname: "/claims/Manual",
                  startdate: this.props.ageStart,
                  enddate: this.props.ageEnd,
                  Location: this.props.Location,
                }}
                onClick={() =>
                  sessionStorage.setItem("queryclient", this.state.client)
                }
                style={{
                  textDecoration: "none",
                  color: "rgb(0 116 235)",
                }}
              >
                <Row
                onClick={() =>
                  sessionStorage.setItem("queryclient", this.state.client)
                }
                >
                  <Col    
                   className="alclaim"
                  >
                    Total Claims:
                    <span>
                      <div
                        style={{
                          color: "black",
                          fontWeight: "600",
                          fontSize: "13px",
                        }}
                      >
                        {this.state.manualcount}
                      </div>
                    </span>
                  </Col>
                  <Col className="altotal">
                  Total Amount
                    <span>
                      {" "}
                      <div
                        style={{
                          color: "black",
                          fontSize: "13px",
                          fontWeight: "600",
                        }}
                      >
                      ${this.state.manualamount.toLocaleString()}
                      </div>
                    </span>
                  </Col>
                </Row>
              </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default InventoryCard;
